import React, { useCallback, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import _map from "lodash/mapValues";
import firebase from "firebase";
import _cloneDeep from "lodash/cloneDeep";

import {
  ACCOUNT_BILLING_PATH,
  BASIC_PLAN,
  SIGN_IN_PATH,
  SITES_PATH,
  subscriptionPlans,
} from "../../config";
import {
  NOTIFICATION_ERROR,
  NotificationsContext,
} from "../../components/providers/NotificationsProvider";
import { firebaseAuth, firestoreDb } from "../../utils/firebase";

const Register = () => {
  const { notify } = useContext(NotificationsContext);
  const [state, setState] = useState({
    password: "",
    email: "",
    name: "",
    planId: BASIC_PLAN,
  });
  const history = useHistory();

  const changeHandler = useCallback(
    e => {
      const newState = _cloneDeep(state);
      newState[e.target.name] = e.target.value;
      setState(newState);
    },
    [state]
  );

  const populateUserData = useCallback(async ({ displayName, uid }) => {
    const account = {
      name: displayName,
      users: [uid],
    };
    const accountId = (await firestoreDb.collection("accounts").add(account))
      .id;
    await firestoreDb
      .doc(`users/${uid}`)
      .set({ accounts: firebase.firestore.FieldValue.arrayUnion(accountId) });
    return [{ ...account, key: accountId }];
  }, []);

  const createNewUser = useCallback(async () => {
    const createResponse = await firebaseAuth.createUserWithEmailAndPassword(
      state.email,
      state.password
    );
    const user = await createResponse.user;
    if (state.name) {
      await user.updateProfile({ displayName: state.name });
    }
    if (!user.emailVerified) {
      await user.sendEmailVerification();
    }
    return user;
  }, [state.email, state.name, state.password]);

  const submitHandler = useCallback(
    async event => {
      event.preventDefault();
      try {
        const user = await createNewUser();
        await populateUserData(user);
        if (typeof window.Intercom !== "undefined") {
          window.Intercom("update", {
            name: state.name,
            email: state.email,
          });
        }
        if (
          typeof state.planId !== "undefined" &&
          state.planId !== BASIC_PLAN
        ) {
          history.push(ACCOUNT_BILLING_PATH);
        }
        history.push(SITES_PATH);
      } catch (error) {
        notify(error, NOTIFICATION_ERROR);
      }
    },
    [
      createNewUser,
      history,
      notify,
      populateUserData,
      state.email,
      state.name,
      state.planId,
    ]
  );

  return (
    <div className="center-block pt-5 mt-5">
      <div className="form-container-centered">
        <div className="text-center">
          <i className="zmdi zmdi-hc-4x zmdi-navigation bg-primary rounded-lg text-white py-1 px-2 mb-3 text-xlg" />
          <h2 className="font-weight-bold mb-3">Register</h2>
          <p className="text-sm mb-5">
            Already have an account? <Link to={SIGN_IN_PATH}>Sign in</Link>
          </p>
        </div>
        <div className="card w-100 p-5 shadow">
          <form onSubmit={submitHandler} noValidate>
            <div className="form-group mb-4">
              <label htmlFor="name" className="text-md mb-1">
                Name
              </label>
              <input
                className="form-control"
                name="name"
                type="text"
                value={state.name}
                required
                onChange={changeHandler}
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="email" className="text-md mb-1">
                Email
              </label>
              <input
                className="form-control"
                name="email"
                type="email"
                value={state.email}
                required
                onChange={changeHandler}
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="password" className="text-md mb-1">
                Password
              </label>
              <input
                className="form-control"
                name="password"
                type="password"
                value={state.password}
                required
                onChange={changeHandler}
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="planId" className="text-md mb-1">
                Subscription Plan
              </label>
              <select
                className="form-control"
                name="planId"
                value={state.planId}
                onBlur={changeHandler}
                onChange={changeHandler}
              >
                {Object.values(
                  _map(subscriptionPlans, (plan, planId) => {
                    return (
                      <option key={planId} value={planId}>
                        {plan.title} plan (
                        {plan.price > 0 ? `$${plan.price}/month` : "free"})
                      </option>
                    );
                  })
                )}
              </select>
            </div>
            <button className="btn btn-primary w-100 mt-4" type="submit">
              <span>Register</span> <i className="zmdi zmdi-account-add ml-1" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
