import React, { useCallback, useContext } from "react";
import { generatePath, useHistory } from "react-router-dom";
import firebase from "firebase";

import { LINKS_LIST_PATH } from "../../../config";
import { AccountContext } from "../../../components/providers/AccountProvider";
import { firestoreDb } from "../../../utils/firebase";

import { STARTED } from "./QueueStatus";

const StartButton = ({ currentSite }) => {
  const history = useHistory();
  const { account } = useContext(AccountContext);

  const startWarming = useCallback(async () => {
    const siteId = currentSite.key;
    const newQueue = { siteId, accountId: account.key, status: STARTED };
    const queueId = (await firestoreDb.collection(`/queues/`).add(newQueue)).id;
    await firestoreDb.collection(`/queues/${queueId}/logs`).add({
      status: STARTED,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      message: STARTED,
    });
    const path = generatePath(LINKS_LIST_PATH, {
      site: siteId,
      queue: queueId,
    });
    history.push(path);
  }, [account.key, currentSite.key, history]);

  if (!account || !currentSite) return null;
  return (
    <button
      name="start-button"
      className="btn btn-block btn-primary text-uppercase"
      onClick={startWarming}
      type="button"
    >
      Start <i className="zmdi zmdi-hc-lg zmdi-play-circle-outline ml-1" />
    </button>
  );
};

export default StartButton;
