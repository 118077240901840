import React from "react";
import PropTypes from "prop-types";

const HeaderBox = ({ header, value, footer }) => (
  <div>
    <p className="header-box__title font-weight-bold">{header}</p>
    <p className="header-box__body text-primary">{value}</p>
    <div>{footer}</div>
  </div>
);

HeaderBox.propTypes = {
  footer: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]),
  header: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
};

HeaderBox.defaultProps = {
  footer: null,
};

export default HeaderBox;
