import React, { useContext } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  SITE_EDIT_PATH,
  SITE_KEYS_PATH,
  SITE_SCHEDULES_PATH,
} from "../../config";
import { AccountContext } from "../../components/providers/AccountProvider";
import LayoutFullNoCard from "../../components/layout/LayoutFullNoCard";
import { useCurrentSite } from "../../components/providers/SitesProvider";

import Queues from "./components/Queues";
import SiteName from "./components/SiteName";
import StartButton from "./components/StartButton";

function SiteView() {
  const { account } = useContext(AccountContext);
  const currentSite = useCurrentSite();

  return (
    <LayoutFullNoCard>
      <div className="site-edit">
        <div className="row">
          <div className="col-lg-3">
            <div className="site-sidebar border-1 border-light bg-white mb-4 p-2 rounded-lg">
              {currentSite && (
                <>
                  <SiteName currentSite={currentSite} />
                  <div className="mb-3">
                    <StartButton
                      accountId={account.key}
                      currentSite={currentSite}
                    />
                  </div>
                  <Queues />
                  <div className="sidebar-menu mt-5">
                    <Link
                      to={generatePath(SITE_EDIT_PATH, {
                        site: currentSite.key,
                      })}
                      className="sidebar-link border-bottom-light d-block p-2"
                    >
                      <i className="zmdi zmdi-settings mr-2" /> Site Settings
                    </Link>
                    <Link
                      to={generatePath(SITE_SCHEDULES_PATH, {
                        site: currentSite.key,
                      })}
                      className="sidebar-link border-bottom-light d-block p-2"
                    >
                      <i className="zmdi zmdi-calendar mr-2" /> Schedules
                    </Link>
                    <Link
                      to={generatePath(SITE_KEYS_PATH, {
                        site: currentSite.key,
                      })}
                      className="sidebar-link border-bottom-light d-block p-2"
                    >
                      <i className="zmdi zmdi-key mr-2" /> Api keys
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-9">
            <div className="card p-4 mt-5 mh-400 pb-9 shadow-sm">
              No queues warming now!
            </div>
          </div>
        </div>
      </div>
    </LayoutFullNoCard>
  );
}

export default SiteView;
