import React, { useEffect, useState } from "react";
import firebase from "firebase";

export default function AsyncImage({ url, className }) {
  const [src, setSrc] = useState("");
  useEffect(() => {
    const ref = firebase.storage().ref(url);
    ref.getDownloadURL().then(data => {
      setSrc(data);
    });
  });
  return <img src={src} className={className} alt="" />;
}
