import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";

const TabsWrapper = ({ tabs, children, startTab, listHeight, width }) => {
  const { queue: newQueue } = useParams();
  const [activeTab, setActiveTab] = useState(startTab);
  const [queue, setQueue] = useState(newQueue);
  useEffect(() => {
    if (queue !== newQueue) setActiveTab(startTab);
    setQueue(newQueue);
  }, [newQueue, queue, startTab]);

  return (
    <div className="links-tabs-wrapper mt-2">
      <div
        className="links-tabs"
        style={{ width: `${width}px`, height: `${listHeight}px` }}
      >
        <ul className="nav nav-tabs position-relative">
          {tabs.map(tab => {
            return (
              <li className="col-6 text-center nav-item" key={`tab-${tab.id}`}>
                <a
                  href={`#${tab.id}`}
                  className={classNames("nav-link", {
                    active: activeTab === tab.id,
                  })}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.title}
                </a>
              </li>
            );
          })}
        </ul>
        {children(activeTab)}
      </div>
    </div>
  );
};

export default TabsWrapper;
