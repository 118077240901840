import React, { createContext, useContext, useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

import { firestoreDb } from "../../utils/firebase";

import { AccountContext } from "./AccountProvider";
import { useErrorNotification } from "./NotificationsProvider";

export const SitesContext = createContext([]);

export const SitesProvider = ({ children }) => {
  const { account } = useContext(AccountContext);
  const query =
    account && account.key
      ? firestoreDb.collection(`accounts/${account.key}/sites`)
      : null;
  const [sites = [], loading, error] = useCollectionData(query, {
    idField: "key",
  });
  useErrorNotification(error);

  const output = !loading ? children : null;

  return <SitesContext.Provider value={sites}>{output}</SitesContext.Provider>;
};

export const useCurrentSite = () => {
  const { site: siteId } = useParams();
  const sites = useContext(SitesContext);
  return useMemo(() => {
    return siteId && sites ? sites.find(s => s.key === siteId) : null;
  }, [siteId, sites]);
};
