import React from "react";

import ValidatedInput from "../../../components/common/ValidatedInput";

export default ({ errorText, value, onChange }) => {
  return (
    <ValidatedInput errorText={errorText}>
      {() => {
        return (
          <div className="form-group row mb-3">
            <label
              htmlFor="username"
              className="col-sm-2 col-form-label text-nowrap"
            >
              Basic Auth
            </label>
            <div className="col-sm-5">
              <input
                id="username"
                className="form-control mb-sm-0 mb-3"
                type="text"
                name="user"
                onChange={onChange}
                placeholder="username"
                defaultValue={(value || {}).user}
                autoComplete="off"
              />
            </div>
            <div className="col-sm-5">
              <input
                id="password"
                className="form-control"
                type="password"
                name="pass"
                autoComplete="new-password"
                onChange={onChange}
                placeholder="password"
                defaultValue={(value || {}).pass}
              />
            </div>
          </div>
        );
      }}
    </ValidatedInput>
  );
};
