import React from "react";

import { MODE_CRAWLER, MODE_SITEMAP } from "../../../config";

export default ({ value, onChange }) => {
  return (
    <div className="row" key="mode">
      <label htmlFor="mode" className="col-sm-2 col-form-label text-nowrap">
        Mode
      </label>
      <div className="col-sm-5 form-check pl-5">
        <input
          id="mode-sitemap"
          type="radio"
          name="mode"
          className="form-check-input"
          value="sitemap"
          checked={value === MODE_SITEMAP}
          onChange={onChange}
        />
        <label htmlFor="mode-sitemap" className="form-check-label">
          Sitemap
        </label>
      </div>
      <div className="col-sm-5 form-check pl-5">
        <input
          id="mode-crawler"
          type="radio"
          name="mode"
          className="form-check-input"
          value="crawler"
          checked={value === MODE_CRAWLER}
          onChange={onChange}
        />
        <label htmlFor="mode-crawler" className="form-check-label">
          Crawler
        </label>
      </div>
    </div>
  );
};
