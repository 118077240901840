import { shape, number, string, arrayOf } from "prop-types";

const sitePropType = shape({
  name: string.isRequired,
  key: string.isRequired,
  url: string.isRequired,
  mode: string.isRequired,
  auth: shape({ user: string.isRequired, pass: string.isRequired }),
  sitemaps: arrayOf(string),
  basePath: string,
  maxDepth: number,
  apiKeys: arrayOf(string),
});

export default sitePropType;
