import React from "react";

import ValidatedInput from "../../../components/common/ValidatedInput";

export default ({ errorText, value, onChange, disabled }) => {
  return (
    <ValidatedInput key="url" errorText={errorText}>
      {({ inputClassName, errorNode }) => {
        return (
          <div className="row">
            <label className="col-sm-2 col-form-label" htmlFor="url">
              Domain
            </label>
            <div className="col-sm-10">
              <input
                id="url"
                className={inputClassName}
                name="url"
                value={value}
                onChange={onChange}
                placeholder="example.com"
                required
                disabled={disabled}
              />
              {errorNode}
            </div>
          </div>
        );
      }}
    </ValidatedInput>
  );
};
