import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ConfirmModal = ({ body, title, btnClassName, btnLabel, onConfirm }) => {
  const [open, setOpen] = useState(false);

  const confirm = useCallback(
    e => {
      onConfirm(e);
      setOpen(false);
    },
    [onConfirm]
  );

  return (
    <span className="confirm">
      <button
        type="button"
        className={classNames("btn", "btn-secondary", {
          [btnClassName]: btnClassName,
        })}
        onClick={() => setOpen(!open)}
        disabled={open}
      >
        <i className="zmdi zmdi-delete mr-1" /> {btnLabel}
      </button>
      {open && (
        <>
          <div className="modal mt-5 d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div
                className="modal-content"
                style={{ whiteSpace: "break-spaces" }}
              >
                {title && (
                  <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                  </div>
                )}
                {body && <div className="modal-body">{body}</div>}
                <div className="modal-footer">
                  <button
                    className="btn btn-primary mr-2 confirm-button"
                    name="confirm-button"
                    onClick={confirm}
                    type="button"
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn cancel-button"
                    name="cancel-button"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show d-block" />
        </>
      )}
    </span>
  );
};
ConfirmModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  btnClassName: PropTypes.string,
  btnLabel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
};
export default ConfirmModal;
