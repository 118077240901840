import React, { useContext } from "react";
import { generatePath, NavLink } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { LINKS_LIST_PATH } from "../../../config";
import { AccountContext } from "../../../components/providers/AccountProvider";
import { firestoreDb } from "../../../utils/firebase";
import { formatTimestamp } from "../../../utils/helperFunctions";
import { useCurrentSite } from "../../../components/providers/SitesProvider";
import { useErrorNotification } from "../../../components/providers/NotificationsProvider";

import { QueuesPagination } from "./QueuesPagination";
import { useQueueFiltering } from "./useQueuesFiltering";

const Queues = () => {
  const currentSite = useCurrentSite();
  const { account } = useContext(AccountContext);
  const [values = [], loading, error] = useCollectionData(
    firestoreDb
      .collection("queues")
      .where("siteId", "==", currentSite?.key)
      .where("accountId", "==", account.key),
    { idField: "key" }
  );
  useErrorNotification(error);
  const { queues, years, currentPage, setCurrentPage } = useQueueFiltering(
    values
  );

  if (loading) return "Loading...";
  if (!currentSite || !queues || !queues.length) return null;
  return (
    <div className="queues-menu card">
      <ul
        className="queues-menu__list nav nav-pills flex-column"
        style={{ overflow: "auto" }}
      >
        {queues.map(queueItem => {
          const linksListPath = generatePath(LINKS_LIST_PATH, {
            site: currentSite?.key,
            queue: queueItem.key,
          });
          return (
            <li className="nav-item" key={queueItem.key}>
              <NavLink
                to={linksListPath}
                className="nav-link border-bottom-light"
                activeClassName="active"
              >
                {queueItem.updatedAt && formatTimestamp(queueItem.updatedAt)}
              </NavLink>
            </li>
          );
        })}
      </ul>
      <QueuesPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        years={years}
      />
    </div>
  );
};

export default Queues;
