import React from "react";
import { useParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import format from "date-fns/format";
import addSeconds from "date-fns/addSeconds";
import differenceInSeconds from "date-fns/differenceInSeconds";

import { firestoreDb } from "../../../utils/firebase";

/**
 *
 * @param {Date} firstDate
 * @param {Date} secondDate
 */
const formatDiff = (firstDate, secondDate) => {
  if (!firstDate || !secondDate) {
    return "00:00";
  }
  const seconds = differenceInSeconds(firstDate, secondDate);
  var helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, "mm:ss");
};

const Timeline = () => {
  const { queue: queueId } = useParams();
  const [values, loading, error] = useCollectionData(
    firestoreDb
      .collection(`/queues/${queueId}/logs`)
      .orderBy("timestamp", "desc")
  );

  if (loading) return "Loading...";
  if (error) return error;
  if (!values || !values.length) return null;

  return (
    <ul className="list-group statuses-list mb-0">
      {values.map((log, index) => {
        const date = log.timestamp ? log.timestamp.toDate() : new Date();
        const prevDate =
          values[index - 1] && values[index - 1].timestamp
            ? values[index - 1].timestamp.toDate()
            : new Date();
        return (
          <li key={index} className="list-group-item">
            {format(date, "h:mm:ss a")}
            {index !== 0 && (
              <span className="spent-time ml-3">
                {formatDiff(prevDate, date)}
              </span>
            )}
            <span className="badge badge-default badge-pill ml-3 text-secondary">
              {log.status}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default Timeline;
