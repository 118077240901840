import { Redirect, Route, Switch } from "react-router-dom";
import React, { useContext } from "react";

import {
  LINKS_LIST_PATH,
  PASSWORD_PATH,
  REGISTER_PATH,
  SIGN_IN_PATH,
  SITE_CREATE_PATH,
  SITE_EDIT_PATH,
  SITE_PATH,
  SITES_PATH,
} from "../config";
import LayoutMinimal from "../components/layout/LayoutMinimal";
import { AccountContext } from "../components/providers/AccountProvider";
import { SitesProvider } from "../components/providers/SitesProvider";

import { Dashboard } from "./dashboard";
import { ForgotPassword, Register, SignIn } from "./auth";
import { SiteAdd, SiteEdit } from "./sites";
import { Links, SiteView } from "./process";

const Main = () => {
  const { isAuthenticated } = useContext(AccountContext);
  if (isAuthenticated) {
    return (
      <SitesProvider>
        <Switch>
          <Route exact component={Dashboard} path={SITES_PATH} />
          <Route exact component={SiteAdd} path={SITE_CREATE_PATH} />
          <Route exact component={SiteEdit} path={SITE_EDIT_PATH} />
          <Route component={SiteView} path={SITE_PATH} />
          <Route component={Links} path={LINKS_LIST_PATH} />
          <Redirect to={SITES_PATH} />
        </Switch>
      </SitesProvider>
    );
  }
  return (
    <LayoutMinimal>
      <Switch>
        <Route component={ForgotPassword} path={PASSWORD_PATH} />
        <Route component={Register} path={REGISTER_PATH} />
        <Route component={SignIn} path={SIGN_IN_PATH} />
        <Redirect to={SIGN_IN_PATH} />
      </Switch>
    </LayoutMinimal>
  );
};

export default Main;
