import React, { useCallback } from "react";
import classNames from "classnames";
import { FixedSizeList as List } from "react-window";

import { useCurrentSite } from "../../../components/providers/SitesProvider";

const LinksList = ({ links, loading, width, height }) => {
  const currentSite = useCurrentSite();
  const renderLink = useCallback(
    url => {
      if (!url) return null;
      let link = "";
      if (currentSite) {
        link = `${currentSite.url}/${url}`
          .replace(/\/{2,}/g, "/")
          .replace(":/", "://");
      }
      return (
        <span>
          <a
            href={link}
            className="mr-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="zmdi zmdi-open-in-new" />
          </a>
          {url}
        </span>
      );
    },
    [currentSite]
  );

  const renderStatusCode = useCallback(statusCode => {
    if (!statusCode) return null;
    const className = classNames({
      "text-success": statusCode < 300,
      "text-warning": statusCode >= 300 && statusCode < 400,
      "text-danger": statusCode >= 400,
    });
    return <span className={className}>{statusCode}</span>;
  }, []);

  const renderRow = useCallback(
    ({ index, style }) => {
      const item = links[index];
      if (!item || !item.url) return null;
      const { url, statusCode, elapsedTime } = item;
      return (
        <div className="link-row pl-3 d-flex border-bottom-light" style={style}>
          <div className="col-md-8 link-col text-truncate">
            {renderLink(url)}
          </div>
          <div className="col-md-2 link-col">
            {renderStatusCode(statusCode)}
          </div>
          <div className="col-md-2 link-col">
            {elapsedTime ? `${elapsedTime / 1000} sec` : ""}
          </div>
        </div>
      );
    },
    [links, renderLink, renderStatusCode]
  );

  if (loading || !links || !currentSite) return null;
  return links.length ? (
    <div className="links-wrapper">
      <div
        className="link-row pl-3 d-flex links-header font-weight-bold text-uppercase pr-3"
        style={{ width: width }}
      >
        <div className="col-md-8 link-col">URL</div>
        <div className="col-md-2 link-col">STATUS</div>
        <div className="col-md-2 link-col">TIME</div>
      </div>
      <List
        className="List"
        height={height}
        itemCount={links.length}
        itemSize={35}
        width={width}
      >
        {renderRow}
      </List>
    </div>
  ) : null;
};
export default LinksList;
