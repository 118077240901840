import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import useOnClickOutside from "../../utils/useClickOutside";

const Dropdown = ({ btnLabel, items, btnClass }) => {
  const { ref, open, setOpen } = useOnClickOutside(false);
  return (
    <div className="dropdown" ref={ref}>
      <button className={btnClass} type="button" onClick={() => setOpen(!open)}>
        {btnLabel}
      </button>
      {open && (
        <div className="dropdown-menu d-block">
          {items &&
            items.map((item, index) => {
              const ItemComponent = item.to ? Link : "button";
              return (
                <ItemComponent
                  {...item}
                  key={item.key || index}
                  className={classNames("dropdown-item", item.className)}
                  onClick={e => {
                    setOpen(false);
                    item.onClick && item.onClick(e);
                  }}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
