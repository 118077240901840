import format from "date-fns/format";

export const getSitesList = collection => {
  const sites = [];
  collection.docs.forEach(doc => {
    const site = doc.data();
    site.key = doc.id;
    sites.push(site);
  });
  return sites;
};

export const formatTimestamp = timestamp =>
  typeof timestamp === "number" || timestamp instanceof Date
    ? format(timestamp, "MMM dd, h:mm aa")
    : timestamp;
