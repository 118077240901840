import React, { createContext, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { firebaseAuth, firestoreDb } from "../../utils/firebase";

import { useErrorNotification } from "./NotificationsProvider";

export const AccountContext = createContext([]);

export const AccountProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(firebaseAuth);
  useErrorNotification(error);

  const query =
    user && user.uid
      ? firestoreDb
          .collection("accounts")
          .where("users", "array-contains-any", [user.uid])
      : null;
  const [accounts, accountsLoading, accountsError] = useCollectionData(query, {
    idField: "key",
  });
  useErrorNotification(accountsError);

  const contextValue = useMemo(
    () => ({
      user,
      initializing: loading || accountsLoading,
      account: accounts ? accounts[0] : null,
      isAuthenticated: !!user,
    }),
    [user, loading, accountsLoading, accounts]
  );

  const output = !loading && !accountsLoading ? children : null;
  return (
    <AccountContext.Provider value={contextValue}>
      {output}
    </AccountContext.Provider>
  );
};
