import React from "react";

const AddSiteHints = () => {
  return (
    <div className="card mb-3">
      <div className="card-header">Hints</div>
      <div className="card-block">
        <ul>
          <li>
            You do not have to specify the scheme (
            <span className="text-primary">http://</span> or{" "}
            <span className="text-primary">https://</span>) - we will get it
            automatically.
          </li>
          <li>Do not specify a URL path.</li>
          <li>
            If your site requires{" "}
            <a
              href="https://en.wikipedia.org/wiki/Basic_access_authentication"
              target="_blank"
              rel="noopener noreferrer"
            >
              Basic Authentication
            </a>
            , we will ask for it.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AddSiteHints;
