import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDocumentData } from "react-firebase-hooks/firestore";

import {
  ACCOUNT_BILLING_PATH,
  BASIC_PLAN,
  subscriptionPlans,
} from "../../config";
import { AccountContext } from "../providers/AccountProvider";
import { firestoreDb } from "../../utils/firebase";
import { useErrorNotification } from "../providers/NotificationsProvider";

const today = new Date();

const UsageBar = () => {
  const { account } = useContext(AccountContext);
  const subscriptionPlan = account?.planId ? account.planId : BASIC_PLAN;
  const [isOpen, setIsOpen] = useState(false);
  const plan = subscriptionPlans[subscriptionPlan];

  const query = account
    ? firestoreDb.doc(
        `accounts/${account?.key}/counters/${today.getFullYear()}`
      )
    : null;
  const [doc, docLoading, error] = useDocumentData(query, {
    idField: "key",
  });
  useErrorNotification(error);

  const counterValue = useMemo(() => {
    const currentMonth = today.getMonth() + 1;
    return doc ? doc[currentMonth] : 0;
  }, [doc]);

  const progress = useMemo(() => {
    return plan.limit
      ? counterValue / parseInt(plan.limit.replace(/,/g, ""), 10)
      : 0;
  }, [counterValue, plan.limit]);

  const progressValue = useMemo(() => {
    return Math.round(progress * 100);
  }, [progress]);

  if (!doc || docLoading) return null;

  return (
    <>
      <div
        className="progress"
        onFocus={() => setIsOpen(true)}
        onMouseOver={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
        onMouseOut={() => setIsOpen(false)}
      >
        {isOpen && (
          <div className="tooltip tooltip-top show">
            <div className="tooltip-inner text-sm text">
              Usage this month: <br /> {counterValue} pages out of {plan.limit}
            </div>
          </div>
        )}
        <div
          className={classNames("progress-bar bg-primary", {
            "text-gray-dark": progressValue < 4,
          })}
          role="progressbar"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="1"
          style={{ width: `${progressValue}%` }}
        >
          {progress >= 1 ? (
            <Link to={ACCOUNT_BILLING_PATH}>100% - Upgrade!</Link>
          ) : (
            <span>{`${progressValue}%`}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default UsageBar;
