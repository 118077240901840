import React from "react";
import { generatePath, Link } from "react-router-dom";

import { SITE_EDIT_PATH, SITE_PATH } from "../../../config";
import { useCurrentSite } from "../../../components/providers/SitesProvider";

const SiteName = () => {
  const currentSite = useCurrentSite();
  if (!currentSite || !currentSite.key || !currentSite.name) return null;
  const siteEditPath = generatePath(SITE_EDIT_PATH, { site: currentSite.key });
  const sitePath = generatePath(SITE_PATH, { site: currentSite.key });
  return (
    <div className="site-name-card card mb-3 p-3">
      <h5 className="pr-5 m-0 text-truncate">{currentSite.name}</h5>
      <div className="site-name-card__icon position-absolute">
        <Link to={siteEditPath}>
          <i className="zmdi zmdi-hc-lg zmdi-settings mr-2" />
        </Link>
        <Link to={sitePath} className="site-warm">
          <i className="zmdi zmdi-hc-2x zmdi-chevron-right" />
        </Link>
      </div>
    </div>
  );
};
export default SiteName;
