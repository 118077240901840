import React, { useCallback, useMemo } from "react";
import format from "date-fns/format";

import Dropdown from "../../../components/common/Dropdown";

const getMonth = month => {
  const date = new Date();
  date.setMonth(month);
  return format(date, "MMMM");
};

export const QueuesPagination = ({ currentPage, setCurrentPage, years }) => {
  const yearsArray = Object.keys(years);

  const setYear = useCallback(
    value => {
      const isActive = currentPage.year === value;
      if (!isActive) {
        const newCurrentPage = Object.assign({}, currentPage);
        newCurrentPage.year = value;
        const months = years[value];
        newCurrentPage.month = months[0];
        setCurrentPage(newCurrentPage);
      }
    },
    [currentPage, setCurrentPage, years]
  );

  const setMonth = useCallback(
    value => {
      const isActive = currentPage.month === value;
      if (!isActive) {
        const newCurrentPage = Object.assign({}, currentPage);
        newCurrentPage.month = value;
        setCurrentPage(newCurrentPage);
      }
    },
    [currentPage, setCurrentPage]
  );

  const year = currentPage.year;
  const months = year ? years[year] : [];

  const yearItems = useMemo(
    () =>
      yearsArray.map(value => ({
        onClick: () => setYear(value),
        children: value,
      })),
    [setYear, yearsArray]
  );

  const monthsItems = useMemo(
    () =>
      months.map(value => ({
        onClick: () => setMonth(value),
        children: getMonth(value),
      })),
    [months, setMonth]
  );

  if (
    !currentPage ||
    !currentPage.year ||
    !years ||
    !Object.keys(years).length
  ) {
    return null;
  }

  return (
    <nav className="card-footer">
      <div className="row">
        {months.length && currentPage.month && (
          <div className="col-7 col-lg-12 col-xl-7">
            <Dropdown
              btnLabel={getMonth(currentPage.month)}
              btnClass="btn btn-secondary btn-block dropdown-toggle"
              items={monthsItems}
            />
          </div>
        )}
        {yearsArray.length && (
          <div className="col-5 col-lg-12 col-xl-5 mb-lg-2 mb-xl-0">
            <Dropdown
              btnLabel={currentPage.year}
              btnClass="btn btn-secondary btn-block dropdown-toggle"
              items={yearItems}
            />
          </div>
        )}
      </div>
    </nav>
  );
};
