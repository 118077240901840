import React from "react";

import ValidatedInput from "../../../components/common/ValidatedInput";

export default ({ errorText, value, onChange }) => {
  return (
    <ValidatedInput errorText={errorText} key="maxDepth">
      {({ inputClassName, errorNode }) => {
        return (
          <div className="row">
            <label
              className="col-sm-2 col-form-label text-nowrap"
              htmlFor="maxDepth"
            >
              Max Depth
            </label>
            <div className="col-sm-10">
              <input
                id="maxDepth"
                className={inputClassName}
                type="number"
                min={1}
                max={10}
                step={1}
                name="maxDepth"
                value={value}
                onChange={onChange}
                required
              />
              {errorNode}
            </div>
          </div>
        );
      }}
    </ValidatedInput>
  );
};
