import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";

import { PASSWORD_PATH, REGISTER_PATH } from "../../config";
import { firebaseAuth } from "../../utils/firebase";
import {
  NOTIFICATION_ERROR,
  NotificationsContext,
} from "../../components/providers/NotificationsProvider";

const SignIn = () => {
  const { notify } = useContext(NotificationsContext);
  const emailInput = React.createRef();
  const passwordInput = React.createRef();

  const onSubmit = useCallback(
    async event => {
      event.preventDefault();
      const result = await firebaseAuth
        .signInWithEmailAndPassword(
          emailInput.current.value,
          passwordInput.current.value
        )
        .catch(error => {
          notify(error.message, NOTIFICATION_ERROR);
        });
      if (result) {
        const user = result.user;
        if (typeof dataLayer !== "undefined") {
          window.dataLayer.push({
            event: "User Loaded",
            logged_in: true,
            userId: user.uid,
            email: user.email,
            name: user.displayName || user.email,
          });
        }
      }
    },
    [emailInput, notify, passwordInput]
  );

  return (
    <div className="center-block pt-5 mt-5">
      <div className="form-container-centered">
        <div className="text-center">
          <i className="zmdi zmdi-hc-4x zmdi-navigation bg-primary rounded-lg text-white py-1 px-2 mb-3 text-xlg" />
          <h2 className="font-weight-bold mb-3">Sign in to your account</h2>
          <p className="text-sm mb-5">
            Don’t have an account yet?{" "}
            <Link to={REGISTER_PATH}>Register now!</Link>
          </p>
        </div>
        <div className="card w-100 p-5 shadow">
          <form onSubmit={onSubmit} noValidate>
            <div className="form-group mb-4">
              <label htmlFor="email" className="text-md mb-1">
                Email
              </label>
              <input
                className="form-control"
                name="email"
                type="email"
                ref={emailInput}
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="password" className="text-md mb-1">
                Password
              </label>
              <input
                className="form-control"
                name="password"
                type="password"
                ref={passwordInput}
              />
            </div>
            <div className="form-group mb-4">
              <Link
                to={PASSWORD_PATH}
                className="text-blue text-sm float-right"
              >
                Forgot password?
              </Link>
            </div>
            <button className="btn btn-primary w-100 mt-4" type="submit">
              <span>Sign In</span> <i className="zmdi zmdi-sign-in ml-1" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
