import { useMemo, useState } from "react";
import _uniq from "lodash/uniq";
import format from "date-fns/format";

const getYear = timestamp => format(timestamp, "yyyy");

const getMonth = timestamp => format(timestamp, "MM");

const getYears = queues => {
  const years = {};
  if (queues && queues.length) {
    const formatted = queues.map(queue => {
      const year = getYear(queue?.updatedAt);
      const month = getMonth(queue?.updatedAt);
      const timestamp = queue.updatedAt;
      years[year] = [];
      return { year, month, timestamp };
    });
    formatted.sort((a, b) => b.timestamp - a.timestamp);
    formatted.forEach(item => {
      const year = item.year;
      if (years[year]) {
        years[year].push(item.month);
      }
      years[year] = _uniq(years[year]);
    });
    return years;
  }
  return [];
};

const queuesPaginated = (currentPage, queues) => {
  const currentYear = currentPage?.year;
  const currentMonth = currentPage?.month;
  if (currentYear && currentMonth) {
    return queues.filter(queue => {
      const year = getYear(queue?.updatedAt);
      const month = getMonth(queue?.updatedAt);
      return currentYear === year && currentMonth === month;
    });
  }
  return [];
};

const getCurrentPageInitial = queues => {
  const lastUpdated = queues.length ? queues[0]?.updatedAt : null;
  if (lastUpdated) {
    return {
      year: getYear(lastUpdated),
      month: getMonth(lastUpdated),
    };
  }
  return null;
};

export const useQueueFiltering = values => {
  const [page, setCurrentPage] = useState(null);
  const queuesFormatted = useMemo(() => {
    const queues = values.map(value => {
      return {
        ...value,
        updatedAt: value?.updatedAt
          ? value?.updatedAt?.toDate().getTime()
          : new Date().getTime(),
      };
    });
    queues.sort((a, b) => b.updatedAt - a.updatedAt);
    return queues;
  }, [values]);

  return useMemo(() => {
    const years = getYears(queuesFormatted);
    const currentPage = page ? page : getCurrentPageInitial(queuesFormatted);
    const queues = queuesPaginated(currentPage, queuesFormatted);
    return { queues, years, currentPage, setCurrentPage };
  }, [page, queuesFormatted]);
};
