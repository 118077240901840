import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import HeaderBox from "../../../components/common/HeaderBox";

const QueueHeader = ({ links, activeQueue, queueLoading }) => {
  if (queueLoading || !activeQueue || !links) return null;
  const queueStatus = activeQueue.status;
  const statusValue =
    queueStatus && typeof queueStatus === "string"
      ? queueStatus
      : "NOT STARTED";
  const total = links.length;
  const warmedLinks = links.filter(item => item.processed === true);
  const processed = warmedLinks.length;
  const value = total === 0 ? 0 : Math.round((processed * 100) / total);
  return (
    <div className="queue-info-header">
      <div className="header-box row mb-2">
        <div className="header-box-item text-center col-4">
          <HeaderBox
            header="Total pages"
            value={<span className="h1">{total}</span>}
          />
        </div>
        <div className="header-box-item text-center col-4">
          <div className="circular-progress-wrapper d-inline-block w-50">
            <CircularProgressbar value={value} text={`${value}%`} />
          </div>
        </div>
        <div className="header-box-item text-center col-4">
          <HeaderBox
            header="Status"
            value={<span className="h4">{statusValue}</span>}
          />
        </div>
      </div>
    </div>
  );
};
export default QueueHeader;
