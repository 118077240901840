import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";

import firebaseConfig from "../firebaseConfig.json";

firebase.initializeApp(firebaseConfig.result.sdkConfig);

export const firebaseApp = firebase;
export const firebaseAuth = firebase.auth();
export const firestoreDb = firebase.firestore();
