import React from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

import { firestoreDb } from "../../utils/firebase";
import LayoutFullNoCard from "../../components/layout/LayoutFullNoCard";
import { useErrorNotification } from "../../components/providers/NotificationsProvider";

import SiteName from "./components/SiteName";
import Queues from "./components/Queues";
import WarmControls from "./components/WarmControls";
import QueueHeader from "./components/QueueHeader";
import LinksList from "./components/LinksList";
import TabsWrapper from "./components/TabsWrapper";
import Timeline from "./components/Timeline";

const TAB_LINKS_HEIGHT = 34;
const TAB_CONTENT_BORDER_SIZE = 1;
const TOP_WINDOW_OFFSET = 120;
const CARD_INNER_OFFSET = 200;
const PAGES_TAB = "pages";
const TIMELINE_TAB = "timeline";

const tabs = [
  { id: PAGES_TAB, title: "Pages" },
  { id: TIMELINE_TAB, title: "Timeline" },
];

const Links = () => {
  const mainHeight = window.innerHeight - TOP_WINDOW_OFFSET;
  const listHeight = mainHeight - CARD_INNER_OFFSET;
  const { queue } = useParams();
  const [links, linksLoading, linksError] = useCollectionData(
    firestoreDb.collection(`/queues/${queue}/links`)
  );
  useErrorNotification(linksError);
  const [activeQueue, queueLoading, queueError] = useDocumentData(
    firestoreDb.doc(`/queues/${queue}`),
    {
      idField: "key",
    }
  );
  useErrorNotification(queueError);

  return (
    <LayoutFullNoCard>
      <div className="site-edit">
        <div className="row">
          <div className="col-lg-3">
            <div className="site-sidebar mb-4 p-2">
              <SiteName />
              <WarmControls
                activeQueue={activeQueue}
                queueLoading={queueLoading}
              />
              <Queues />
            </div>
          </div>
          <div className="col-lg-9">
            <div
              className="card p-4 mt-5 pb-9 shadow-sm"
              style={{ height: mainHeight }}
            >
              <div className="queue">
                <QueueHeader
                  links={links}
                  activeQueue={activeQueue}
                  queueLoading={queueLoading}
                />
                <div className="links-filter-wrapper">LinksFilter</div>
                <AutoSizer>
                  {({ width }) => (
                    <TabsWrapper
                      listHeight={listHeight}
                      width={width}
                      tabs={tabs}
                      startTab={PAGES_TAB}
                    >
                      {activeTab => {
                        switch (activeTab) {
                          case PAGES_TAB:
                            return (
                              <LinksList
                                links={links}
                                loading={linksLoading}
                                width={width - TAB_CONTENT_BORDER_SIZE * 2}
                                height={listHeight - TAB_LINKS_HEIGHT}
                              />
                            );

                          case TIMELINE_TAB:
                          default:
                            return <Timeline />;
                        }
                      }}
                    </TabsWrapper>
                  )}
                </AutoSizer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutFullNoCard>
  );
};

export default Links;
