import React, { useCallback, useContext, useState } from "react";
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";
import { Link, useHistory } from "react-router-dom";

import { MODE_CRAWLER, MODE_SITEMAP, SITES_PATH } from "../../config";
import ConfirmModal from "../../components/common/ConfirmModal";
import { AccountContext } from "../../components/providers/AccountProvider";
import {
  NOTIFICATION_ERROR,
  NotificationsContext,
} from "../../components/providers/NotificationsProvider";
import { firestoreDb } from "../../utils/firebase";
import LayoutFull from "../../components/layout/LayoutFull";
import { useCurrentSite } from "../../components/providers/SitesProvider";

import FormValidate from "./components/FormValidate";
import SitePreview from "./components/SitePreview";
import SiteUrl from "./components/SiteUrl";
import Mode from "./components/Mode";
import SiteAuth from "./components/SiteAuth";
import Sitemaps from "./components/Sitemaps";
import MaxDepth from "./components/MaxDepth";
import BasePath from "./components/BasePath";
import SiteNameField from "./components/SiteNameField";

const SiteEdit = () => {
  const { notify } = useContext(NotificationsContext);
  const { account } = useContext(AccountContext);
  const currentSite = useCurrentSite();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState(currentSite);

  const deleteSite = useCallback(() => {
    if (currentSite) {
      setLoading(true);
      firestoreDb
        .doc(`accounts/${account.key}/sites/${currentSite.key}`)
        .delete()
        .then(() => {
          history.push(SITES_PATH);
        })
        .catch(error => {
          notify(error, NOTIFICATION_ERROR);
        });
    }
  }, [currentSite, account.key, history, notify]);

  const onInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      const newValues = _cloneDeep(values);
      _set(newValues, name, value);
      setValues(newValues);
    },
    [values]
  );

  const onInputCollectionChange = useCallback(
    collectionValues => {
      const newValues = _cloneDeep(values);
      _set(newValues, "sitemaps", collectionValues);
      setValues(newValues);
    },
    [values]
  );

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();
      const validationErrors = FormValidate(values);
      if (!validationErrors) {
        setErrors({});
        setLoading(true);
        try {
          await firestoreDb
            .doc(`accounts/${account.key}/sites/${currentSite.key}`)
            .update(values);
          history.push(SITES_PATH);
        } catch (error) {
          notify(error, NOTIFICATION_ERROR);
        }
      } else {
        setErrors(validationErrors);
      }
    },
    [account.key, currentSite.key, history, notify, values]
  );

  const renderConfirmDeleteBody = useCallback(() => {
    return (
      <span>
        You are about to delete{" "}
        <span className="text-primary">{currentSite?.name}</span> and its
        history. It cannot be restored.
        <br />
        Are you sure?
      </span>
    );
  }, [currentSite.name]);

  if (!currentSite || !account) return null;
  if (values) {
    const { name, url, mode, basePath, maxDepth, sitemaps, auth } = values;
    return (
      <LayoutFull>
        <form
          className="site-item site-form mb-4 p-4"
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          <h4 className="text-center mb-4 text-dark font-weight-bold">
            Site Settings
          </h4>
          {errors.form && (
            <div className="alert alert-danger">{errors.form}</div>
          )}
          {currentSite && <SitePreview currentSite={currentSite} />}
          <SiteUrl
            onChange={onInputChange}
            errorText=""
            value={url}
            disabled={true}
          />
          <SiteNameField onChange={onInputChange} errorText="" value={name} />
          <Mode onChange={onInputChange} errorText="" value={mode} />
          {auth && auth.pass && auth.user && (
            <SiteAuth onChange={onInputChange} errorText="" value={maxDepth} />
          )}
          {mode === MODE_SITEMAP && (
            <Sitemaps
              onInputCollectionChange={onInputCollectionChange}
              errorText=""
              value={sitemaps}
            />
          )}
          {mode === MODE_CRAWLER && (
            <MaxDepth onChange={onInputChange} errorText="" value={maxDepth} />
          )}
          {mode === MODE_CRAWLER && (
            <BasePath onChange={onInputChange} errorText="" value={basePath} />
          )}
          <div className="row text-nowrap">
            {isLoading ? (
              <span className="offset-sm-2 col-sm-4">
                <i className="zmdi zmdi-spinner zmdi-hc-spin mr-3" />
                Please wait...
              </span>
            ) : (
              <span className="col-sm-10 offset-sm-2">
                <button
                  type="submit"
                  className="btn btn-primary mr-3"
                  name="save-button"
                >
                  Save
                </button>
                <ConfirmModal
                  title="Delete"
                  body={renderConfirmDeleteBody()}
                  btnLabel="Delete"
                  btnClassName="delete-button mr-3"
                  onConfirm={deleteSite}
                />
                <Link to={SITES_PATH} className="btn btn-secondary">
                  Cancel
                </Link>
              </span>
            )}
          </div>
        </form>
      </LayoutFull>
    );
  } else {
    return (
      <LayoutFull>
        <i className="zmdi zmdi-spinner zmdi-hc-spin mr-3" /> Please wait...
      </LayoutFull>
    );
  }
};

export default SiteEdit;
