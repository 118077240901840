import React, { useCallback } from "react";
import classNames from "classnames";
import firebase from "firebase/app";
import { generatePath, Link, useParams } from "react-router-dom";

import { LINKS_LIST_PATH } from "../../../config";
import { firestoreDb } from "../../../utils/firebase";
import { formatTimestamp } from "../../../utils/helperFunctions";

import { PAUSED, STOPPED, WARMING } from "./QueueStatus";

const WarmControls = ({ activeQueue, queueLoading }) => {
  const { queue, site } = useParams();
  const createLog = useCallback(
    logStatus => {
      firestoreDb.collection(`/queues/${queue}/logs`).add({
        status: logStatus,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
    },
    [queue]
  );
  const linksListPath = generatePath(LINKS_LIST_PATH, { site, queue });
  if (queueLoading || !activeQueue || !activeQueue.updatedAt) {
    return null;
  }
  return (
    <div className="mb-3 nav-pills">
      <div className="warm-controls card card-block p-0">
        <div
          className={classNames("nav-link border-bottom-light p-2", {
            active: queue,
          })}
        >
          <Link to={linksListPath}>
            {formatTimestamp(activeQueue.updatedAt.nanoseconds)}
          </Link>
          <div className="row">
            <span className="col h6" style={{ margin: "5px 0 0" }}>
              {activeQueue.status}
            </span>
            <span className="col align-self-end text-right">
              {activeQueue.status === WARMING && (
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => createLog(PAUSED)}
                >
                  <i className="zmdi zmdi-pause" />
                </button>
              )}

              {activeQueue.status === PAUSED && (
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => createLog(WARMING)}
                >
                  <i className="zmdi zmdi-play" />
                </button>
              )}

              {[WARMING, PAUSED].indexOf(activeQueue.status) > -1 && (
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={() => createLog(STOPPED)}
                >
                  <i className="zmdi zmdi-stop" />
                </button>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarmControls;
