import _merge from "lodash/merge";
import validate from "validate.js";
import _pick from "lodash/pick";
import _forEach from "lodash/forEach";

const customValidators = {
  isSitemaps(values) {
    if (
      !Array.isArray(values) ||
      !values.some(val => typeof val === "string" && val.length)
    ) {
      return "No sitemaps provided.";
    }
    return "";
  },
  isUrl(value) {
    if (!value) {
      return "Domain is required.";
    }

    const isValidUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/)?$/gi.test(
      value
    );
    if (!isValidUrl) {
      return "Domain is not valid. Check hints.";
    }
    return "";
  },
  isAuth(value) {
    if (typeof value !== "object") {
      return "Enter credentials.";
    }

    if (!value.user) {
      return "Enter username.";
    }

    if (!value.pass) {
      return "Enter password.";
    }
    return "";
  },
};

_merge(validate.validators, customValidators);

const constraints = {
  url: {
    isUrl: true,
  },
  name: {
    presence: { message: "Site name is required." },
  },
  sitemaps: {
    isSitemaps: true,
  },
  auth: {
    isAuth: true,
  },
  maxDepth: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 10,
    },
  },
  basePath: {},
};

export default values => {
  const currentConstraints = _pick(constraints, Object.keys(values));
  const errors = validate(values, currentConstraints, { fullMessages: false });
  let hasErrors = false;

  // Transform errors message to set into state object the first error message from messages array.
  _forEach(errors, (value, key) => {
    hasErrors = true;
    if (Array.isArray(value) && value.length) {
      // eslint-disable-next-line prefer-destructuring
      errors[key] = value[0];
    }
  });
  if (hasErrors) {
    return errors;
  }
  return null;
};
