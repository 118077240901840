import React from "react";
import { Link } from "react-router-dom";

import UserMenu from "./UserMenu";

const Header = () => (
  <header className="site-header w-100 py-3 position-absolute">
    <div className="container">
      <div className="row justify-content-between align-items-center">
        <div className="col  flex-shrink-md">
          <Link
            to="/"
            className="header__logo d-inline-block text-white text-lg text-wrap"
          >
            <i className="zmdi zmdi-navigation bg-primary rounded-lg text-white py-1 px-2 mb-3 text-lg" />{" "}
            <span>CacheReady</span>
          </Link>
        </div>

        <div className="col flex-shrink-md">
          <div className="float-right position-relative top-menu">
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
