import React, { useContext } from "react";

import { ACCOUNT_PATH } from "../../config";
import { AccountContext } from "../providers/AccountProvider";
import { firebaseAuth } from "../../utils/firebase";
import Dropdown from "../common/Dropdown";

const UserMenu = () => {
  const { isAuthenticated } = useContext(AccountContext);

  if (!isAuthenticated) return null;
  const items = [
    {
      to: ACCOUNT_PATH,
      className: "border-bottom-light",
      children: (
        <>
          <i className="zmdi zmdi-account mr-2" /> Account
        </>
      ),
    },
    {
      onClick: () => firebaseAuth.signOut(),
      children: (
        <>
          <i className="zmdi zmdi-undo mr-2" /> Sign out
        </>
      ),
    },
  ];

  return (
    <Dropdown
      btnLabel={<i className="zmdi zmdi-menu zmdi-hc-lg" />}
      btnClass="btn btn-white"
      items={items}
    />
  );
};

export default UserMenu;
