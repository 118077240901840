export const API_URL = "/api";
export const SITE_INFO_API = `${API_URL}/site/discover`;

// Route paths
export const HOME_PATH = "/";
export const REGISTER_PATH = "/register";
export const PASSWORD_PATH = "/password";
export const SIGN_IN_PATH = "/sign-in";

export const ACCOUNT_PATH = "/account";
export const ACCOUNT_BILLING_PATH = `${ACCOUNT_PATH}/billing`;

export const SITES_PATH = "/";

export const SITE_PATH = `/site/:site`;
export const SITE_EDIT_PATH = `/site-edit/:site/`;
export const SITE_CREATE_PATH = `/site-create`;
export const SITE_SCHEDULES_PATH = `${SITE_PATH}/schedules`;
export const SITE_KEYS_PATH = `${SITE_PATH}/keys`;

export const HISTORY_PATH = `${SITE_PATH}/history`;
export const LINKS_LIST_PATH = `/warm/:site/:queue`;

export const POST_SIGN_IN_PATH = SITES_PATH;
export const POST_SIGN_OUT_PATH = HOME_PATH;

export const BASIC_PLAN = "basic";
export const PRO_PLAN = "cacheready_pro";
export const UNLIMITED_PLAN = "cacheready_unlimited";

export const subscriptionPlans = {
  [BASIC_PLAN]: { title: "Basic", price: 0, limit: "10,000" },
  [PRO_PLAN]: { title: "Professional", price: 14, limit: "100,000" },
  [UNLIMITED_PLAN]: { title: "Unlimited", price: 49, limit: "500,000" },
};

export const MODE_CRAWLER = "crawler";
export const MODE_SITEMAP = "sitemap";
