import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Validated input wraps regular input with bootstrap classes for invalid input.
 * It runs children as function passing inputClassName and errorNode to it.
 * Usage:
 *
 * <ValidatedInput errorText={errorText}>
 *   {
 *    ({inputClassName, errorNode}) => {
 *      <input className={inputClassName} />
 *      {errorNode}
 *    }
 *   }
 * </ValidatedInput>
 *
 * @param {string} errorText - validation error
 * @param {function} children - function that renders validated input JSX
 * @returns {element} - wrapped input
 * @constructor
 */
const ValidatedInput = ({ errorText, children }) => {
  const hasError = Boolean(errorText);

  return (
    <div className={classNames("form-group mb-4", { "text-danger text-sm": hasError })}>
      {children({
        inputClassName: classNames("form-control", {
          "form-control-danger": hasError,
        }),
        errorNode: hasError && <span>{errorText}</span>,
      })}
    </div>
  );
};

ValidatedInput.propTypes = {
  children: PropTypes.func.isRequired,
  errorText: PropTypes.string,
};

ValidatedInput.defaultProps = {
  errorText: "",
};

export default ValidatedInput;
