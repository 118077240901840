export const STARTED = "STARTED";
export const PARSING = "PARSING";
export const WARMING = "WARMING";
export const PAUSED = "PAUSED";
export const COMPLETE = "COMPLETE";
export const STOPPED = "STOPPED";
export const FAILED = "FAILED";
export const LIMIT_REACHED = "LIMIT_REACHED";

export const completedStatuses = [COMPLETE, STOPPED, FAILED];
export const runningStatuses = [STARTED, PARSING, WARMING, PAUSED];
export const linksRefreshStatus = [
  WARMING,
  PAUSED,
  COMPLETE,
  STOPPED,
  FAILED,
  LIMIT_REACHED,
];
