import React, { useCallback } from "react";
import _filter from "lodash/filter";
import _cloneDeep from "lodash/cloneDeep";

const InputsCollection = ({ values, name, onChange }) => {
  const onInputChange = useCallback(
    (index, e) => {
      const eventValue = e.target.value;
      const newVals = _cloneDeep(values);
      newVals[index] = eventValue;
      onChange(newVals);
    },
    [onChange, values]
  );

  const onAdd = useCallback(() => {
    const newVals = _cloneDeep(values);
    newVals.push("");
    onChange(newVals);
  }, [onChange, values]);

  const onRemove = useCallback(
    index => {
      let newVals = _cloneDeep(values);
      newVals = _filter(newVals, (value, key) => key !== index);
      onChange(newVals);
    },
    [onChange, values]
  );

  return (
    <div className="inputs-collection text-right row">
      {values.map((value, index) => (
        <div
          key={index + "-collection-input"}
          className="form-group col-md-9 mb-3"
        >
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={value}
              name={name}
              onChange={e => onInputChange(index, e)}
            />
            <span className="input-group-btn">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => onRemove(index)}
              >
                <i className="zmdi zmdi-minus-circle-outline  zmdi-hc-2x" />
              </button>
            </span>
          </div>
        </div>
      ))}
      <div className="col-md-3 mt-1">
        <button
          className="btn-sm btn btn-primary"
          type="button"
          onClick={() => onAdd()}
        >
          <i className="zmdi zmdi-plus-circle mr-1" /> Add more
        </button>
      </div>
    </div>
  );
};
export default InputsCollection;
