import React, { useContext } from "react";
import { Link } from "react-router-dom";
import className from "classnames";

import { SITE_CREATE_PATH, UNLIMITED_PLAN } from "../../config";
import { SitesContext } from "../../components/providers/SitesProvider";
import { AccountContext } from "../../components/providers/AccountProvider";
import LayoutFull from "../../components/layout/LayoutFull";
import UsageBar from "../../components/common/UsageBar";

import SiteItem from "./SiteItem";

const Dashboard = () => {
  const sites = useContext(SitesContext);
  const account = useContext(AccountContext);
  return (
    <LayoutFull>
      <div className="sites">
        <header className="row mb-4">
          <div className="col-md-4 position-relative">
            <input
              onChange={() => {}}
              name="search"
              type="search"
              className="search-input form-control pl-5"
              placeholder="Search sites"
              value=""
            />
            <span className="zmdi zmdi-hc-lg zmdi-search search-button position-absolute" />
          </div>
          <div className="col-md-5">
            <div
              className={className("row", {
                hidden: account?.planId === UNLIMITED_PLAN,
              })}
            >
              <label className="col-md-4 col-form-label text-right">
                Usage:{" "}
              </label>
              <div className="col-md-7">
                <UsageBar />
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <Link
              className="btn btn-primary float-right add-site"
              to={SITE_CREATE_PATH}
            >
              <i className="zmdi zmdi-plus-circle mr-1" /> Add Site
            </Link>
          </div>
        </header>
        <div className="sites-list border-top-light animated fade-in">
          {sites.map(site => (
            <SiteItem site={site} key={site.key} />
          ))}
        </div>
      </div>
    </LayoutFull>
  );
};

export default Dashboard;
