import React, { useState, useContext } from "react";
import validate from "validate.js";
import { Link, useHistory } from "react-router-dom";

import { SIGN_IN_PATH } from "../../config";
import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
  NotificationsContext,
} from "../../components/providers/NotificationsProvider";
import { firebaseAuth } from "../../utils/firebase";
import ValidatedInput from "../../components/common/ValidatedInput";

const constraints = {
  email: {
    presence: { message: "Email is required" },
    email: { message: "Email is not valid" },
  },
};

const ForgotPassword = () => {
  const { notify } = useContext(NotificationsContext);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();

  const onSubmit = event => {
    event.preventDefault();
    const errors = validate({ email }, constraints, { fullMessages: false });
    if (errors && errors.email.length) {
      setError(errors.email[0]);
    } else {
      firebaseAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          notify(
            "Check your email for further instructions!",
            NOTIFICATION_SUCCESS
          );
          history.push(SIGN_IN_PATH);
        })
        .catch(error => notify(error.message, NOTIFICATION_ERROR));
    }
  };

  return (
    <div className="center-block pt-5 mt-5">
      <div className="form-container-centered">
        <div className="text-center">
          <i className="zmdi zmdi-hc-4x zmdi-navigation bg-primary rounded-lg text-white py-1 px-2 mb-3 text-xlg" />
          <h2 className="font-weight-bold mb-3">Reset your password</h2>
          <p className="text-sm mb-5">
            I remembered! <Link to={SIGN_IN_PATH}>Let me sign in</Link>
          </p>
        </div>
        <div className="card w-100 p-5 shadow">
          <form onSubmit={onSubmit} noValidate>
            <div className="form-group text-left mb-5">
              <label htmlFor="email" className="text-md mb-1">
                Email
              </label>
              <ValidatedInput errorText={error}>
                {({ inputClassName }) => {
                  return (
                    <span>
                      <input
                        className={inputClassName}
                        id="email"
                        name="email"
                        type="email"
                        onChange={e => setEmail(e.target.value)}
                      />
                      {error}
                    </span>
                  );
                }}
              </ValidatedInput>
            </div>
            <button className="btn btn-primary w-100" type="submit">
              Send me password reset instructions
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
