import { useCallback, useEffect, useRef, useState } from "react";

export default function useClickOutside(initialIsVisible) {
  const [open, setOpen] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = useCallback(
    event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    },
    [ref, setOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, open, setOpen };
}
