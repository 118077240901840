import React, { useCallback, useContext, useState } from "react";
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";
import { Link, useHistory, generatePath } from "react-router-dom";

import {
  MODE_CRAWLER,
  MODE_SITEMAP,
  SITE_PATH,
  SITES_PATH,
} from "../../config";
import { AccountContext } from "../../components/providers/AccountProvider";
import {
  NOTIFICATION_ERROR,
  NotificationsContext,
} from "../../components/providers/NotificationsProvider";
import { firestoreDb } from "../../utils/firebase";
import SiteName from "../process/components/SiteName";
import LayoutFull from "../../components/layout/LayoutFull";

import FormValidate from "./components/FormValidate";
import SiteUrl from "./components/SiteUrl";
import Mode from "./components/Mode";
import Sitemaps from "./components/Sitemaps";
import MaxDepth from "./components/MaxDepth";
import BasePath from "./components/BasePath";
import VerifySite from "./components/VerifySite";
import AddSiteHints from "./components/AddSiteHints";

const SiteAdd = () => {
  const { notify } = useContext(NotificationsContext);
  const { account } = useContext(AccountContext);
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [final, setFinal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [values, setValues] = useState({ url: "", mode: MODE_SITEMAP });

  const onInputChange = useCallback(
    e => {
      const { name, value } = e.target;
      const newValues = _cloneDeep(values);
      _set(newValues, name, value);
      setValues(newValues);
    },
    [values, setValues]
  );

  const onInputCollectionChange = useCallback(
    collectionValues => {
      const newValues = _cloneDeep(values);
      _set(newValues, "sitemaps", collectionValues);
      setValues(newValues);
    },
    [values, setValues]
  );

  const handleFormSubmit = useCallback(
    async e => {
      e.preventDefault();
      const validationErrors = FormValidate(values);
      if (!validationErrors) {
        setErrors({});
        setLoading(true);
        try {
          const collection = firestoreDb.collection(
            `accounts/${account.key}/sites`
          );
          const site = await collection.add(values);
          const siteObj = await site.get();
          const path = generatePath(SITE_PATH, {
            site: siteObj.id,
          });
          history.push(path);
        } catch (error) {
          notify(error, NOTIFICATION_ERROR);
        }
      } else {
        setErrors(validationErrors);
      }
    },
    [account.key, history, notify, values]
  );

  const updateSiteInfo = useCallback(
    ({ title: name, url, sitemaps }) => {
      if (name || Array.isArray(sitemaps)) {
        setValues({
          ...values,
          name,
          url,
          mode: sitemaps.length ? MODE_SITEMAP : MODE_CRAWLER,
          basePath: "/",
          maxDepth: 2,
          sitemaps,
        });
        setFinal(true);
      }
    },
    [values, setValues, setFinal]
  );

  const renderForm = useCallback(() => {
    const { name, url, mode, basePath, maxDepth, sitemaps } = values;
    if (final) {
      return (
        <form
          className="site-form mb-4"
          autoComplete="off"
          noValidate
          onSubmit={handleFormSubmit}
        >
          {errors.form && (
            <div className="alert alert-danger">{errors.form}</div>
          )}

          <SiteUrl
            onChange={onInputChange}
            errorText=""
            value={url}
            disabled={false}
          />
          <SiteName onChange={onInputChange} errorText="" value={name} />
          <Mode onChange={onInputChange} errorText="" value={mode} />

          {mode === MODE_SITEMAP && (
            <Sitemaps
              onInputCollectionChange={onInputCollectionChange}
              errorText=""
              value={sitemaps}
            />
          )}
          {mode === MODE_CRAWLER && (
            <MaxDepth onChange={onInputChange} errorText="" value={maxDepth} />
          )}
          {mode === MODE_CRAWLER && (
            <BasePath onChange={onInputChange} errorText="" value={basePath} />
          )}

          <div className="row text-nowrap">
            {isLoading ? (
              <span className="offset-sm-2 col-sm-4">
                <i className="zmdi zmdi-spinner zmdi-hc-spin mr-3" />
                Please wait...
              </span>
            ) : (
              <span className="col-sm-10 offset-sm-2">
                <button
                  type="submit"
                  className="btn btn-primary mr-3"
                  name="save-button"
                >
                  Save
                </button>
                <Link to={SITES_PATH} className="btn btn-secondary">
                  Cancel
                </Link>
              </span>
            )}
          </div>
        </form>
      );
    } else {
      return <VerifySite updateSiteInfo={updateSiteInfo} />;
    }
  }, [
    errors.form,
    final,
    handleFormSubmit,
    isLoading,
    onInputChange,
    onInputCollectionChange,
    updateSiteInfo,
    values,
  ]);

  return (
    <LayoutFull>
      <div className="row">
        <h4 className="col-sm-12 mb-3">Add new site</h4>
      </div>
      <div className="row">
        <div className="col-lg-6">{renderForm()}</div>
        <div className="col-lg-6">
          <AddSiteHints />
        </div>
      </div>
    </LayoutFull>
  );
};

export default SiteAdd;
