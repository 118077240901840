import React from "react";

import ValidatedInput from "../../../components/common/ValidatedInput";

export default ({ errorText, value, onChange }) => {
  return (
    <ValidatedInput errorText={errorText} key="basePath">
      {({ inputClassName, errorNode }) => {
        return (
          <div className="row">
            <label
              className="col-sm-2 col-form-label text-nowrap"
              htmlFor="basePath"
            >
              Base Path
            </label>
            <div className="col-sm-10">
              <input
                id="basePath"
                className={inputClassName}
                type="text"
                name="basePath"
                value={value}
                onChange={onChange}
                required
              />
              {errorNode}
            </div>
          </div>
        );
      }}
    </ValidatedInput>
  );
};
