import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import Main from "./pages/Main";
import { AccountProvider } from "./components/providers/AccountProvider";
import * as serviceWorker from "./serviceWorker";
import "./styles/styles.scss";
import { NotificationsProvider } from "./components/providers/NotificationsProvider";
import Header from "./components/layout/Header";

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <NotificationsProvider>
      <AccountProvider>
        <div className="App">
          <Router history={history}>
            <Header />
            <Main />
          </Router>
        </div>
      </AccountProvider>
    </NotificationsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
