import React, {
  useRef,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import NotificationSystem from "react-notification-system";

export const NotificationsContext = createContext();
export const NOTIFICATION_ERROR = "error";
export const NOTIFICATION_SUCCESS = "success";

export const NotificationsProvider = ({ children }) => {
  const notificationsRef = useRef(null);

  const notify = useCallback(
    (message, level = NOTIFICATION_SUCCESS) => {
      if (notificationsRef.current) {
        notificationsRef.current.addNotification({
          message,
          level,
        });
      }
    },
    [notificationsRef]
  );

  return (
    <NotificationsContext.Provider value={{ notify }}>
      <NotificationSystem ref={notificationsRef} />
      {children}
    </NotificationsContext.Provider>
  );
};

export const useErrorNotification = errorMessage => {
  const { notify } = useContext(NotificationsContext);
  useEffect(() => {
    if (errorMessage) {
      notify(errorMessage, NOTIFICATION_ERROR);
    }
  }, [errorMessage, notify]);
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
