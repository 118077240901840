import React from "react";

import ValidatedInput from "../../../components/common/ValidatedInput";

import InputsCollection from "./InputsCollection";

export default ({ errorText, value, onInputCollectionChange }) => {
  return (
    <ValidatedInput errorText={errorText} key="sitemaps">
      {({ errorNode }) => {
        return (
          <div className="row">
            <label htmlFor="sitemaps[]" className="col-sm-2 col-form-label">
              Sitemap location(s)
            </label>
            <div className="col-sm-10">
              <InputsCollection
                name="sitemaps[]"
                values={value}
                onChange={onInputCollectionChange}
              />
              {errorNode}
            </div>
          </div>
        );
      }}
    </ValidatedInput>
  );
};
