import { Link } from "react-router-dom";
import React, { useCallback, useState } from "react";

import { SITE_INFO_API, SITES_PATH } from "../../../config";
import { firebaseAuth } from "../../../utils/firebase";

import SiteUrl from "./SiteUrl";
import SiteAuth from "./SiteAuth";

export default function VerifySite({ updateSiteInfo }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");
  const [auth, setAuth] = useState({});

  const getSiteInfo = useCallback(async () => {
    let requestUrl = `${SITE_INFO_API}?url=${url}`;
    if (typeof auth === "object") {
      requestUrl += `&auth=${auth.user}:${auth.pass}`;
    }
    const idToken = firebaseAuth.currentUser.getIdToken(true);
    const response = await fetch(requestUrl, {
      mode: "cors",
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      }),
    });
    const json = await response.json();
    return json;
  }, [auth, url]);

  const handleFormSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getSiteInfo();
      setIsLoading(false);
      if (response.status === 401) {
        if (showAuth) {
          setError("Credentials are wrong.");
        } else {
          setShowAuth(true);
        }
      } else {
        updateSiteInfo({ ...response, auth });
      }
    } catch (e) {
      setError("Site verify Error.");
      setIsLoading(false);
    }
  }, [auth, getSiteInfo, showAuth, updateSiteInfo]);

  const authChange = useCallback(
    e => {
      const { name, value } = e.target;
      const newAuth = Object.assign({}, auth);
      if (name === "user") newAuth.user = value;
      if (name === "pass") newAuth.pass = value;
      setAuth(newAuth);
    },
    [auth]
  );

  return (
    <div className="site-form mb-4">
      <SiteUrl
        onChange={e => setUrl(e.target.value)}
        errorText=""
        value={url}
      />
      {showAuth && (
        <SiteAuth onChange={authChange} errorText={error} value={auth} />
      )}
      <div className="row text-nowrap">
        {isLoading ? (
          <span className="offset-sm-2 col-sm-4">
            <i className="zmdi zmdi-spinner zmdi-hc-spin mr-3" />
            Please wait...
          </span>
        ) : (
          <span className="col-sm-10 offset-sm-2">
            <button
              type="submit"
              className="btn btn-primary mr-3"
              name="next-button"
              onClick={() => handleFormSubmit()}
            >
              Next
            </button>
            <Link to={SITES_PATH} className="btn btn-secondary">
              Cancel
            </Link>
          </span>
        )}
      </div>
    </div>
  );
}
