import React, { useCallback, useContext, useState } from "react";
import firebaseApp from "firebase";

import {
  NOTIFICATION_ERROR,
  NotificationsContext,
} from "../../../components/providers/NotificationsProvider";
import AsyncImage from "../../../components/common/AsyncImage";

const SitePreview = ({ currentSite }) => {
  const imageUrl = currentSite?.key
    ? "site_previews/" + currentSite?.key + ".png"
    : "";
  const { notify } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);

  const photoRefresh = useCallback(() => {
    const { url, auth, key } = currentSite;
    setLoading(true);
    const refreshScreen = firebaseApp
      .functions()
      .httpsCallable("siteScreenshotHttps");
    refreshScreen({ auth, siteId: key, url })
      .then(result => setLoading(false))
      .catch(error => notify(error, NOTIFICATION_ERROR));
  }, [currentSite, notify]);

  if (!currentSite) return null;
  return (
    <div className="form-group row mb-3">
      <label htmlFor="site-name" className="col-sm-2 col-form-label">
        Preview
      </label>
      <div
        className="col-sm-2 d-flex justify-content-center align-items-center position-relative"
        style={{ minHeight: "100px" }}
      >
        {loading && (
          <i className="zmdi zmdi-hc-2x zmdi-spinner zmdi-hc-spin mr-3 text-white higher-layer" />
        )}
        <AsyncImage url={imageUrl} className="w-75 mx-4 position-absolute" />
      </div>
      <div className="col-sm-3">
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => photoRefresh()}
        >
          <i className="zmdi zmdi-refresh" /> Refresh
        </button>
      </div>
    </div>
  );
};

export default SitePreview;
