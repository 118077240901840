import React from "react";
import { generatePath, Link } from "react-router-dom";

import { SITE_EDIT_PATH, SITE_PATH } from "../../config";
import AsyncImage from "../../components/common/AsyncImage";
import sitePropType from "../../components/common/sitePropType";

const SiteItem = ({ site }) => {
  const imgName = "site_previews/" + site.key + ".png";

  return (
    <div className="site-row row py-3 ml-0 w-100 border-bottom-light">
      <div className="col-lg-9">
        <div className="row">
          <div className="col-md-2 text-center">
            <AsyncImage url={imgName} className="mw-100 w-100" />
          </div>
          <div className="col-md-10">
            <Link
              className="clearfix site-link font-weight-bold h6 text-secondary"
              to={generatePath(SITE_PATH, { site: site.key })}
            >
              {site.name}
            </Link>
            <a href={site.url} className="clearfix site-link">
              <i className="zmdi mr-1 zmdi-link" /> {site.url}
            </a>
          </div>
        </div>
      </div>
      <div className="col-lg-3 text-center">
        <Link
          className="btn btn-light m-2 w-10"
          to={generatePath(SITE_PATH, { site: site.key })}
        >
          <i className="zmdi zmdi-refresh-sync" /> Warm
          <i className="zmdi zmdi-chevron-right ml-2" />
        </Link>
        <Link
          className="btn btn-light m-2 w-10"
          to={generatePath(SITE_EDIT_PATH, { site: site.key })}
        >
          <i className="zmdi zmdi-settings" /> Settings
        </Link>
      </div>
    </div>
  );
};

SiteItem.propTypes = {
  site: sitePropType.isRequired,
};

export default SiteItem;
